<template>
  <div id="app" class="has-image">
    <iframe src="https://playhouselights-camera-app.ngrok.io/" />
    <div id="controls">
        <button v-bind:class="{ active: isRain }" class="rain" @click.prevent="rain()">
            &#128166; R A I N &#128166;
        </button>
        <button v-bind:class="{ active: isStack }" class="stack" @click.prevent="stack()">
            T E T R I S &nbsp; S T A C K
        </button>
        <button v-bind:class="{ active: isRainbow }" class="rainbow" @click.prevent="rainbow()">
            <span class="red-font">R</span>
            <span class="orange-font">A</span>
            <span class="yellow-font">I</span>
            <span class="green-font">N</span>
            <span class="blue-font">B</span>
            <span class="purple-font">O</span>
            <span class="magenta-font">W</span>
        </button>
        <button v-bind:class="{ active: isActive({ r:255, g:0, b:0 })}" class="red" @click.prevent="sendColor({ r:255, g:0, b:0 })">Red</button>
        <button v-bind:class="{ active: isActive({ r:0, g:0, b:255 })}" class="blue" @click.prevent="sendColor({ r:0, g:0, b:255 })">Blue</button>
        <button v-bind:class="{ active: isActive({ r:0, g:255, b:0 })}" class="green" @click.prevent="sendColor({ r:0, g:255, b:0 })">Green</button>
        <button v-bind:class="{ active: isActive({ r:255, g:127, b:0 })}" class="orange" @click.prevent="sendColor({ r:255, g:127, b:0 })">Orange</button>
        <button v-bind:class="{ active: isActive({ r:255, g:255, b:0 })}" class="yellow" @click.prevent="sendColor({ r:255, g:255, b:0 })">Yellow</button>
        <button v-bind:class="{ active: isActive({ r:255, g:0, b:255 })}" class="magenta" @click.prevent="sendColor({ r:255, g:0, b:255 })">Magenta</button>
        <button v-bind:class="{ active: isActive({ r:0, g:255, b:255 })}" class="cyan" @click.prevent="sendColor({ r:0, g: 255, b:255 })">Cyan</button>
        <color-picker
            id="color-picker"
            :width="width"
            :height="height"
            :margin="0"
            :padding="0"
            @input:change="onColorChange"
            v-model="color"
        />
        <button v-bind:class="{ active: isActive({ r:0, g:0, b:0 })}" @click.prevent="sendColor({ r:0, g:0, b:0 })">OFF</button>
        <router-view></router-view>
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import ColorPicker from './components/ColorPicker.vue';

Vue.component('color-picker', ColorPicker);

export default {
  name: 'App',
  data: () => {
    return {
      color: '#ffffff',
      commandWs: null,
      isRainbow: false,
      isRain: false,
      isStack: false,
      isXmas: false,
    };
  },
  computed: {
    isActive() {
        return color => {
            const rgb = this.hexToRgb(this.color);
            return (rgb.r === color.r && rgb.g === color.g && rgb.b === color.b);
        };
    },
    width() {
        return window.innerWidth - 50;
    },
    height() {
        return window.innerWidth - 50;
    },
  },
  methods: {
    sendColor: async function(color) {
      const message = JSON.stringify({
        name: "RgbColor",
        properties: {
            rgb: color,
        },
      });
      this.sendCommand(message);
    },
    rainbow() {
      const message = JSON.stringify({
        name: "Rainbow",
        properties: {},
      });
      this.sendCommand(message);
    },
    rain() {
      const message = JSON.stringify({
        name: "Rain",
        properties: {},
      });
      this.sendCommand(message);
    },
    stack() {
      const message = JSON.stringify({
        name: "Stack",
        properties: {},
      });
      this.sendCommand(message);
    },
    xmas() {
      const message = JSON.stringify({
        name: "Xmas",
        properties: {},
      });
      this.sendCommand(message);
    },
    onColorChange(e) {
      this.sendColor(e.color.rgb);
    },
    sendCommand(message) {
      if (!this.commandWs || this.commandWs.readyState === WebSocket.CLOSED) {
        this.createCommandWs();
      }
      if (this.commandWs.readyState === WebSocket.OPEN) {
        this.commandWs.send(message);
        console.log('sent message', message);
      }
    },
    createCommandWs() {
        console.log("Starting connection to Command WebSocket Server")
        this.commandWs = new WebSocket(`wss://playhouselights-api.ngrok.io`);

        this.commandWs.onerror = function () {
          console.log('WebSocket error');
        };
        this.commandWs.onopen = function () {
          console.log('WebSocket connection established');
        };
        this.commandWs.onclose = function () {
          console.log('WebSocket connection closed');
          this.commandWs = null;
        };
        this.commandWs.onmessage = (event) => {
          console.log(event.data);
          const message = JSON.parse(event.data);
          if (message.name === 'RgbColor') {
            const color = message.properties.rgb;
            this.color = this.rgbToHex(color.r, color.g, color.b); 
            this.isRainbow = false;
            this.isStack = false;
            this.isRain = false;
            this.isXmas = false;
          }
          if (message.name === 'Rainbow') {
            this.isRainbow = true;
            this.isRain = false;
            this.isStack = false;
            this.isXmas = false;
            this.color = '#ffffff';
          }
          if (message.name === 'Stack') {
            this.isStack = true;
            this.isRainbow = false;
            this.isRain = false;
            this.isXmas = false;
            this.color = '#ffffff';
          }
          if (message.name === 'Rain') {
            this.isRainbow = false;
            this.isRain = true;
            this.isStack = false;
            this.isXmas = false;
            this.color = '#ffffff';
          }
          if (message.name === 'Xmas') {
            this.isRainbow = false;
            this.isRain = false;
            this.isStack = false;
            this.isXmas = true;
            this.color = '#ffffff';
          }
        }
    },
    onVisibilityChange() {
        if (document.hidden) {
            this.commandWs.close();
        } else {
            this.createCommandWs();
        }
    },
    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },
    rgbToHex(r, g, b) {
      return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    setColor(rgb) {
      this.color = this.rgbToHex(rgb.r, rgb.g, rgb.b);
    },
  },
  created: function() {
    this.createCommandWs();
    document.addEventListener('visibilitychange', this.onVisibilityChange);
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

#app.has-image {
  padding-top: 75%;
}

#app iframe {
  width: 96vw;
  height: 66vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 1% 2%;
  background: #fff;
}

#app #controls {
  width: 100%;
}

#app button {
  display: block;
  color: #000;
  width: 100%;
  border: 1px solid #000;
  padding: 50px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

#app button.active {
  border: 6px solid #000;
  color: #000;
  font-weight: bold;
}

button.rainbow,
button.rain,
button.xmas,
button.stack {
  background-color: #fff;
  font-size: 25px;
  font-weight: bold;
}

button.rainbow span,
button.rain,
button.xmas,
button.stack span {
  background-color: #fff;
  display: inline-block;
  padding: 0 10px;
}

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.green {
  background-color: green;
}

.yellow {
  background-color: yellow;
}

.magenta {
  background-color: magenta;
}

.cyan {
  background-color: cyan;
}

.orange {
  background-color: orange;
}

.red-font {
  color: red;
}

.blue-font {
  color: blue;
}

.green-font {
  color: green;
}

.yellow-font {
  color: yellow;
}

.magenta-font {
  color: magenta;
}

.cyan-font {
  color: cyan;
}

.orange-font {
  color: orange;
}
#color-picker {
  width: 50%;
  margin: 90px 0;
}
</style>
