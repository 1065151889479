import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Brightness from './components/Brightness'

Vue.config.productionTip = false

Vue.use(VueRouter)

const routes = [
  { path: '/brightness', component: Brightness },
];

const router = new VueRouter({
  routes // short for `routes: routes`
});

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
