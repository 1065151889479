<template>
  <div id="brightness">
    <button @click.prevent="sendBrightness(0)">0</button>
    <button @click.prevent="sendBrightness(.1)">.1</button>
    <button @click.prevent="sendBrightness(.3)">.3</button>
    <button @click.prevent="sendBrightness(.5)">.5</button>
    <button @click.prevent="sendBrightness(.7)">.7</button>
    <button @click.prevent="sendBrightness(1)">1</button>
  </div>
</template>

<script>

export default {
  name: 'Brightness',
  methods: {
    sendBrightness(value) {
      const message = JSON.stringify({
        name: "Brightness",
        properties: {
            value: value,
        },
      });
      this.sendWsMessage(message);
    },
    sendWsMessage(message) {
      if (!this.wsConnection || this.wsConnection.readyState === WebSocket.CLOSED) {
        this.createWsConnection();
      }
      if (this.wsConnection.readyState === WebSocket.OPEN) {
        this.wsConnection.send(message);
        console.log('sent message', message);
      }
    },
    createWsConnection() {
        console.log("Starting connection to WebSocket Server")
        this.wsConnection = new WebSocket(`wss://playhouselights-api.ngrok.io`);

        this.wsConnection.onerror = function () {
          console.log('WebSocket error');
        };
        this.wsConnection.onopen = function () {
          console.log('WebSocket connection established');
        };
        this.wsConnection.onclose = function () {
          console.log('WebSocket connection closed');
          this.wsConnection = null;
        };
        this.wsConnection.onmessage = function(event) {
          console.log(event);
        }
    },
    onVisibilityChange() {
        if (document.hidden) {
            this.wsConnection.close();
        } else {
            this.createWsConnection();
        }
    },
  },
  created: function() {
    this.createWsConnection();
  },
}
</script>
